<template>
  <div style="overflow-x:hidden">
    <main class="z-0 hidden h-screen w-screen min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
      <header class="flex h-12 min-h-[48px] w-full items-center justify-between border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4">
        <button class="flex select-none items-center overflow-hidden rounded-md transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--label-title)] focus:ring-offset-4 focus:ring-offset-[var(--bg-sub)]">
          <img src="../assets/images/logo.png" />
        </button>

        <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>款式广场</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
  
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/mypic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的作品</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/myfav"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的收藏</label></a>
                        </div>
                        <div class="flex items-center">
                        <a href="/pic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">款式广场</label></a>
                        </div>
                       
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
        </div>
          

        <div class="flex items-center gap-4">
          <div class="flex items-center gap-2">
            <button @click="onhead('/regist')" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-1.5 pr-2 h-7 rounded-lg gap-1 text-xs border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r4r:" data-state="closed" data-type="primary">
              <div class="flex items-center h-4 w-4">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.232 2.287A.75.75 0 0 1 13.75 3v6.25H19a.75.75 0 0 1 .607 1.191l-8 11a.75.75 0 0 1-1.357-.44v-6.25H5a.75.75 0 0 1-.607-1.192l8-11a.75.75 0 0 1 .839-.272Z" fill="currentColor"></path>
                </svg>
              </div>
              <span>注册会员</span>
            </button>
            <button @click="onhead('/login')" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-1.5 pr-2 h-7 rounded-lg gap-1 text-xs border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r4r:" data-state="closed" data-type="primary">
              <div class="flex items-center h-4 w-4">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.232 2.287A.75.75 0 0 1 13.75 3v6.25H19a.75.75 0 0 1 .607 1.191l-8 11a.75.75 0 0 1-1.357-.44v-6.25H5a.75.75 0 0 1-.607-1.192l8-11a.75.75 0 0 1 .839-.272Z" fill="currentColor"></path>
                </svg>
              </div>
              <span>会员登录</span>
            </button>
          </div>
        </div>
      </header>
      <div class="relative h-full max-h-[calc(100%-48px)] w-full justify-end items-center justify-center">
         <div class="my-4 mx-4 rounded-2xl h-25 overflow-hidden h-[608px]"><img src="https://qnimage1.inshoes.cn/Homimages/8650d3db5d843eaa8f3af8b9906ae398.jpg" class="rounded-2xl"></div>
         <div class="max-w-[1625px] m-auto items-center justify-center">
         <div class="mt-6 w-full space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-6 lg:space-y-0">
            <div v-for="(callout,index) in items" :key="callout.name" class="group relative" @click="onlogin">
                <div class="relative h-100 w-full overflow-hidden rounded-lg bg-white group-hover:opacity-75 sm:aspect-w-2 sm:aspect-h-1 sm:h-164 lg:aspect-w-1 lg:aspect-h-1">
                  <img :src="callout.sampath" :alt="callout.pattername" class="h-full w-full object-cover object-center" />
                </div>
                  <h3 class="mt-6 text-sm text-gray-200">
                 <a :href="callout.href">
                  <span class="absolute inset-0" />
                 {{ callout.pattername }}
                 </a>
                </h3>
                <p class="text-base font-semibold text-gray-900">{{ callout.pattername }}</p>
            </div>
          </div>
        </div>
        <div class="w-full text-center text-[11px]">Copyright © 2023 mshoes All Rights Reserved. 卖鞋网 版权所有 备案号：	<a href="https://beian.miit.gov.cn" target="_blank">粤ICP备10240509号</a></div>
      </div>
      
    </main>
    
    <div class="flex fixed items-center p-6 max-w sm mx-auto bg-gray-200 rounded-lg space-x-4 a z-10" style="left:45%;top:40%" v-show="onshow.value">
          <div>
            <div class="text-xl font-medium text-black">请登录再看</div>
            <p class="text-slate-500">
              <button type="button" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300" @click="clickce">取消</button>
              <button type="button" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300" @click="logince">马上登录</button>
            </p>
          </div>
     </div>
    </div>
  </template>
  
  
  <script setup>
  import { ref,onMounted } from 'vue'
  import {setCookie,getCookie,delCookie} from '../utils/cookieok';
  import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
  import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  } from '@heroicons/vue/24/outline'
  import Header from '../components/Header.vue'
  import { ChevronDownIcon } from '@heroicons/vue/20/solid'
  import { homepic,vitoken } from '../api/ajaxApi';
  import { useRouter } from 'vue-router'
  const router = useRouter()
  const items = ref([]);
  const onshow=ref(false);
  const fetchData = () => {
         setTimeout(async () => {
         const newItems = await generateItems(); // 替换为您的数据获取逻辑
         items.value = [...items.value, ...newItems];
        }, 1000); // 模拟延迟
    };
    const navigation = [
    { name: '首页', href: '/', current: true }, 
    { name: '图文创款', href: '/txttoimg', current: false }, 
    { name: '款式再创', href: '/imgtoimg', current: false }, 
    { name: '款式融合', href: '/styletoimg', current: false }, 
    { name: '手稿配色', href: '/cannytoimg', current: false },
    { name: '图转线稿', href: '/imgtocanny', current: false }, 
    { name: '局部重绘', href: '/extratoimg', current: false },
    { name: '换色换料', href: '/colortoimg', current: false },
    { name: '智能抠图', href: '/rembg', current: false },
  ]
  const generateItems = async () => {
        const newItems = [];
        try {
              const res = await homepic(); // 使用 await 等待异步操作完成
               if (res.success) {
                 newItems.push(...res.data.data); // 将数据展开并添加到 newItems 数组中
            }
        } catch (err) {
         console.error(err);
        }
        return newItems;
    };
    onMounted(() => {
      document.title = 'AI鞋款创意设计工具';
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('ac')) {
          let ac = urlParams.get('ac');
          if (ac=="loginout"){
            setCookie('usertoken', '', 7)
          }
       }
      fetchData()
    })
  function onlogin(){
    if(getCookie("usertoken")){
    let params={
      token:getCookie("usertoken"),
     }
     vitoken(params).then(res => {
      if (res.success) {
        router.push('/pic');
      }
      else
      {
          router.push('/login');
      }
      })
    }
    else{
      onshow.value=ref(true)
    }
  }
  function onhead(href){
    // let params={
    //   token:getCookie("usertoken"),
    //  }
    //  vitoken(params).then(res => {
    //   if (res.success) {
       
    //   }
    //   else
    //   {
    //       router.push('/login');
    //   }
    //   })
      router.push(href);
  }

  

  function logince(){
    router.push('/login');
  }
  function clickce(){
    onshow.value=ref(false)
  }
  </script>
<style>
.inner::-webkit-scrollbar {
    display: none;
}
</style>