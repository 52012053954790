import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Pic from '../views/Pic.vue'
import Login from '../views/Login.vue'
import Regist from '../views/Regist.vue'
import Mypic from '../views/Mypic.vue'
import Myfav from '../views/Myfav.vue'
import Txttoimg from '../views/Txttoimg.vue'
import Imgtoimg from '../views/Imgtoimg.vue'
import Cannytoimg from '../views/Cannytoimg.vue'
import Imgtocanny from '../views/Imgtocanny.vue'
import Extratoimg from '../views/Extratoimg.vue'
import Colortoimg from '../views/Colortoimg.vue'
import Sutoimg from '../views/Sutoimg.vue'
import Multitoimg from '../views/Multitoimg.vue'
import Error from '../views/404.vue'
import Profile from '../views/Profile.vue'
import Repassword from '../views/Repassword.vue'
import Conlog from '../views/Conlog.vue'
import Menpic from '../views/Menpic.vue'
import Mentxttoimg from '../views/Mentxttoimg.vue'
import Styletoimg from '../views/Styletoimg.vue'
import Removegb from '../views/Removebg.vue'
import Select from '../views/Select.vue'
import Forgotpass from '../views/Forgotpass.vue'
import Paintingtoimg from '../views/Paintingtoimg.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'AI鞋款创意设计工具',
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/pic',
    name: 'pic',
    component: Pic
  },{
    path:'/login',
    name:'login',
    component:Login
  },{
    path:'/regist',
    name:'regist',
    component:Regist
  },{
    path:'/mypic',
    name:'mypic',
    component:Mypic
  },{
    path:'/myfav',
    name:'myfav',
    component:Myfav
  },{
    path:'/txttoimg',
    name:'txttoimg',
    component:Txttoimg
  },{
    path:'/imgtoimg',
    name:'imgtoimg',
    component:Imgtoimg
  },{
    path:'/cannytoimg',
    name:'cannytoimg',
    component:Cannytoimg
  },{
    path:'/imgtocanny',
    name:'imgtocanny',
    component:Imgtocanny
  },{
    path:'/extratoimg/',
    name:'extratoimg',
    component:Extratoimg
  },{
    path:'/colortoimg',
    name:'colortoimg',
    component:Colortoimg
  },{
    path:'/sutoimg',
    name:'sutoimg',
    component:Sutoimg
  },{
    path:'/multitoimg',
    name:'multitoimg',
    component:Multitoimg
  },{
    path:'/profile',
    name:'profile',
    component:Profile
  },{
    path:'/repassword',
    name:'repassword',
    component:Repassword
  },{
    path:'/conlog',
    name:'conlog',
    component:Conlog
  },{
    path:'/menpic',
    name:'menpic',
    component:Menpic
  },{
    path:'/mentxttoimg',
    name:'mentxttoimg',
    component:Mentxttoimg
  },{
    path:'/styletoimg',
    name:'styletoimg',
    component:Styletoimg
  },{
    path:'/rembg',
    name:'rembg',
    component:Removegb
  },{
    path:'/select',
    name:'select',
    component:Select
  },{
    path:'/forgotpass',
    name:'forgotpass',
    component:Forgotpass
  },{
    path:'/paintingtoimg',
    name:'paintingtoimg',
    component:Paintingtoimg
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
